/* import only the necessary Bootstrap files */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/* -------begin customization-------- */
/* change the primary theme color, and add a new theme color */
// $theme-colors: (
//   primary: red
// );

html, body {
    height: 100%;
    margin: 0;
}

.dropdown-toggle::after {
    margin-top: 0.5em;
    float: right;
}

.dropdown-menu {
    width: 100%;
    overflow-y: scroll;
    max-height: 30em;
}

/* -------end customization-------- */

/* finally, import Bootstrap to set the changes! */
@import "bootstrap";
