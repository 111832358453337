//const loginStyle = { width: "50%", margin: "auto", backgroundColor: "transparent", border: "none" };
.loginStyle {
    width: 50%;
    margin: auto;
    background-color: transparent;
    border: none;
}

@media only screen and (max-width: 576px) {
    .loginStyle {
        width: 100%;
    }
}
