.dataCard {
    transition-duration: 100ms;
    box-shadow: 5px 5px 5px gray;
}

.dataCard:hover {
    background-color: #e4e4e4;
    transition-duration: 100ms;
    transform: translateY(-0.5em);
    box-shadow: 0px 8px 5px #636262;
}
