.page-link {
    border-radius: 50%;
    position: relative;
    display: block;
    color: hsl(0, 0%, 0%);
    text-decoration: none;
    background-color: transparent;
    border-style: none;
    /* border: 1px solid #dee2e6; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.page-item:last-child .page-link {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.page-item:first-child .page-link {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #C18A12;
    border-color: #C18A12;
}

.page-link:focus {
    box-shadow: none !important;
    border-color: transparent !important;
}