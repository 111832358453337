.logo--standardkai {
    font-family: 標楷體;
    font-size: 1.5rem;
}

.application_date {
    font-family: 標楷體;
    font-size: 0.8rem;
}

.application_description {
    font-family: 標楷體;
    font-size: 0.8rem;
}

.application_table {
    border: 1px solid black;
    font-family: 標楷體;
}

/* .application_table_td {
    background-color: white;
} */

.application_table_td,
.application_table_th {
    font-family: 標楷體;
    border: 2px solid black;
}

#application {
    font-family: 標楷體;
}

@page {
    size: A4 portrait;
    margin: 1.5rem;
}

@media print {

    html,
    body {
        width: 210mm;
        height: 297mm;
    }

    body * {
        visibility: hidden;
    }

    #application,
    #application * {
        visibility: visible;
    }

    #application {
        font-family: 標楷體;
        position: absolute;
        left: 0;
        top: 0;
    }
}